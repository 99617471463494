<template>
  <desktopSelectModal
    v-if="openDesktopModal"
    :currentSelected="desktopData"
    @close="openDesktopModal = false"
    @desktopSelected="onDesktopSelect"
    @desktopRemove="onDesktopRemove"
  />
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6">
      <div v-if="pageLoading" class="flex h-full items-center justify-center">
        <Spinner size="large" />
      </div>
      <div
        class="mt-8 flex h-full flex-wrap gap-6"
        :class="[{ 'w-full items-center justify-center ': csvMenu === false }]"
        v-if="!pageLoading"
      >
        <div>
          <div class="shrink-0">
            <div
              @dragover.prevent
              @drop="handleDrop"
              class="flex items-center justify-center rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 px-6 py-8 dark:border-blue-700 dark:bg-blue-900/50"
              :class="[{ 'h-[50vh] w-[100vh] ': csvMenu === false }]"
            >
              <div class="space-y-1 text-center">
                <div class="">
                  <p
                    class="mt-3 text-base font-semibold text-blue-600 sm:text-sm"
                  >
                    Drag and drop a CSV file here
                  </p>
                  <p
                    class="mt-3 text-base font-semibold text-blue-600 sm:text-sm"
                  >
                    OR
                  </p>
                  <label
                    for="file-upload"
                    class="relative mt-1 inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
                  >
                    <SvgIcon class="h-5 w-5" name="upload" />
                    <span class="ml-2"> Browse files </span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                      accept=".csv"
                      ref="file"
                      v-on:change="handleFileUpload()"
                    />
                  </label>
                  <div class="m-4 text-blue-500 flex items-center gap-2">
                    <svg
                      class="shrink-0 h-4 w-4"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        opacity="0.12"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p class="text-base font-semibold text-blue-600 sm:text-sm">
                      NOTE: Select CSV with header.
                    </p>
                  </div>

                  <p class="mt-8 text-sm font-medium text-blue-400">
                    {{ fileName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="csvMenu" class="h-full flex-1">
          <div v-if="loading" class="flex h-full items-center justify-center">
            <Spinner size="large" />
          </div>
          <ValidationForm
            v-else
            :platformInputs="platformInputs"
            :previousNodes="previousNodes"
            :inputs="inputData"
            :triggerValidation="triggerValidation"
            :triggerFormStatus="triggerFormStatus"
            @validationSuccess="validationSuccess"
            @validationFailed="validationFailed"
            @change="selectUpdate"
            @input-update="onInputUpdate"
            @form-status="checkAndUpdateValidationStatus"
          />
        </div>
      </div>
      <div class="mt-8 flex items-center justify-end gap-x-2">
        <Button
          @click="openDesktopModal = true"
          v-if="showDesktopButton === true"
          :text="
            desktopSelected === false ? 'Select Desktop' : desktopData.name
          "
          :color="desktopSelected === false ? 'tertiary' : 'success'"
          rightIcon="desktop"
        />
        <!-- If the user has not verified either their email or phone, display this button -->

        <div>
          <Button
            v-if="!isVerified"
            color="gray"
            @click="showMessage"
            text="Run"
            rightIcon="right-arrow"
          />
          <Button
            v-else
            :text="buttonText"
            :rightIcon="rightIcon"
            :color="buttonColor"
            :disabled="isDisabled"
            @click="handleClick"
          />
        </div>
      </div>
    </div>
  </div>
  <ToastContainer class="w-1/5" ref="toast" />
</template>
<script>
import { uploadCsv } from '@/apis/automation-store/csv'
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import {
  getWorkflowNode,
  pauseSchedule,
  getWorkflowAllNodes
} from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions, mapState } from 'vuex'
import { constants } from '@/common/constants'
import ObjectID from 'bson-objectid'
import desktopSelectModal from '@/components/automationStore/desktopSelectModal.vue'
import { showVerifyMessage } from '@/common/functions/automationStore'
export default {
  name: 'CsvMain',
  components: {
    SvgIcon,
    Input,
    Select,
    Button,
    ValidationForm,
    Spinner,
    ToastContainer,
    desktopSelectModal
  },
  props: {
    validateForm: {
      required: true
    }
  },
  watch: {
    validateForm(newVal, oldVal) {
      if (newVal === true) {
        this.triggerValidation = true
      }
    }
  },
  data() {
    return {
      file: null,
      csvUrl: null,
      headers: null,
      platformInputs: [],
      triggerValidation: false,
      triggerFormStatus: false,
      loading: false,
      csvInputData: null,
      pageLoading: true,
      csvMenu: false,
      inputData: {},
      previousNodes: null,
      showDesktopButton: false,
      openDesktopModal: false,
      desktopSelected: false,
      desktopData: null,
      connectedAccounts: null
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['delayAutomationNaming']),
    ...mapState('automationStore', ['automationNameText']),
    ...mapState('automationStore', ['automationOutputMode']),
    ...mapState('automationStore', ['csvHeaders']),
    ...mapState('workflow', ['dynamicOutputs']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['automationDesktopData']),
    ...mapState('user', ['desktopUnlimited', 'userData']),
    fileName() {
      return this.file
        ? this.file.name
        : this.inputData.fileName
        ? this.inputData.fileName
        : null
    },
    isVerified() {
      return this.userData.isEmailVerified && this.userData.isPhoneVerified
    },
    buttonText() {
      return this.automationScheduleData ? 'Schedule' : 'Run'
    },
    buttonColor() {
      return this.validationStatus ? 'successFilled' : 'gray'
    },
    isDisabled() {
      return !this.validationStatus
    },
    rightIcon() {
      return 'right-arrow'
    }
  },
  async created() {
    await this.checkAndGetData()
    this.pageLoading = false
    this.triggerFormStatus = true
  },
  methods: {
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['addSecondNodeId']),
    ...mapActions('automationStore', ['setDelayAutomationNaming']),
    ...mapActions('automationStore', ['addAutomationInputData']),
    ...mapActions('automationStore', ['removeAutomationInputData']),
    ...mapActions('automationStore', ['setValidationStatus']),
    ...mapActions('automationStore', ['addCsvHeaders']),
    ...mapActions('workflow', ['setDynamicOutputs']),
    ...mapActions('workflow', ['resetVariables']),
    ...mapActions('automationStore', ['addAutomationDesktopData']),

    handleClick() {
      this.triggerValidation = true
    },
    async checkAndGetData() {
      this.setDesktopToggle()
      //check if inputs are stored in vuex
      if (Object.keys(this.automationInputs).length > 0) {
        this.csvUrl = this.automationInputs.csvUrl
        // prefill all the inputs stored in vuex
        this.inputData = this.automationInputs

        this.inputData['connectedAccountId'] =
          this.automationInputs.connectedAccountId
        this.headers = this.csvHeaders
        await this.getAccounts()
        this.csvMenu = true
      } else if (
        //check if vuex is empty as well as node id is provided in url
        Object.keys(this.automationInputs).length === 0 &&
        !!this.$route.query.nodeId
      ) {
        const response = await getWorkflowNode(
          this.$route.query.workflowId,
          this.$route.query.nodeId
        )
        this.csvUrl = response.data.inputs.csvUrl
        this.headers = response.data.inputs.csvHeaders
        this.addCsvHeaders({
          payload: this.headers
        })

        const secondNode = await getWorkflowNode(
          this.$route.query.workflowId,
          this.$route.query.secondNodeId
        )
        //set the inputs with the api inputs
        this.inputData = {
          ...this.inputData,
          ...secondNode.data.inputs
        }

        this.addAutomationInputData({
          payload: this.inputData
        })

        this.inputData['connectedAccountId'] =
          secondNode.data.connectedAccountId
        await this.getAccounts()
        this.csvMenu = true
      }
    },
    async onInputUpdate(inputs, validationResult) {
      //add inputs in vuex
      for (const input in inputs) {
        if (input === 'connectedAccountId') {
          this.addConnectedAccountId({
            payload: inputs[input] === '' ? null : inputs[input]
          })
        }
        if (input === 'threadText') continue
        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }
      //update vuex validation status
      if (validationResult.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
      const inputObj = { ...inputs }

      // update connected account picture on every change
      const account = this.connectedAccounts?.find(
        acc => acc._id === inputObj.connectedAccountId
      )

      this.twitterProfileData = account
      this.platformInputs = this.platformInputs.map(input => {
        if (input.name === 'connectedAccountId') {
          return {
            ...input,
            leftImageUrl: account?.platform?.picture
          }
        }
        if (input.name === 'messageContent') {
          return {
            ...input,
            isPremium: Boolean(account?.isPremium)
          }
        }
        return input
      })
    },

    handleDrop(event) {
      event.preventDefault()
      this.file = event.dataTransfer.files[0]
      this.fileReader()
      this.uploadCsvFile()
    },

    async handleFileUpload() {
      let connectedAccountId = this.inputData['connectedAccountId']
      this.inputData = {}
      this.inputData = {
        connectedAccountId
      }
      this.removeAutomationInputData()
      this.platformInputs = []
      this.file = this.$refs.file.files[0]
      this.fileReader()
      this.uploadCsvFile()
    },

    fileReader() {
      const reader = new FileReader()
      reader.readAsText(this.file)
      reader.onload = () => {
        var header = reader.result.split(/[\r\n]+/)[0] // contains the file content as a string
        let arr = header.split(',').map(item => {
          if (item.startsWith('"') && item.endsWith('"')) {
            return item.slice(1, -1)
          } else {
            return item
          }
        })
        this.headers = arr
        this.addCsvHeaders({
          payload: this.headers
        })
      }
      reader.onerror = () => {
        this.$emit('error', reader.error)
      }
    },

    async getAccounts() {
      let nodeId
      if (this.dynamicOutputs.size === 0) {
        nodeId = ObjectID().toHexString()
      } else {
        const keys = this.dynamicOutputs.keys()
        nodeId = keys.next().value
      }
      if (this.$route.query.nodeId) {
        const res = await getWorkflowAllNodes(this.$route.query.workflowId)
        nodeId = res.data[0]._id
      }

      this.resetVariables()
      this.setDynamicOutputs({
        nodeId,
        dynamicOutputs: this.headers
      })
      this.platformInputs.push(...this.automationStoreData.variablesData)
      for (let data of this.automationStoreData.inputList) {
        let obj = {
          ...data
        }
        delete obj['position']
        this.platformInputs.push({
          ...obj
        })
      }

      this.previousNodes = [
        {
          id: nodeId,
          label: 'Get data from csv',
          order: 1,
          platformId: constants.csvPlatformId,
          platformOperationId: constants.csvPlatformOperationId,
          logoUrl:
            'https://assets-of-v2.s3.amazonaws.com/platforms/logos/CSV.svg'
        }
      ]

      let response
      if (this.automationStoreData.authType) {
        if (this.automationStoreData.isSocialAccountOptional) {
          return
        }
        response = await getConnectedAccounts(
          this.automationStoreData.platformId
        )

        this.connectedAccounts = response.data

        this.platformInputs.unshift({
          name: 'connectedAccountId',
          type: 'select',
          label: `Account:`,
          isRequired: this.automationStoreData.isOptional ? false : true,
          choices: response.data.map(account => ({
            value: account._id,
            label: account.name
          })),
          description:
            this.automationStoreData.platform === 'Google'
              ? `Your Rocket Scrape/Scraper API Account`
              : `Your ${this.automationStoreData.platform} Account`
        })

        //no account found redirect user to integration
        if (
          response.data.length === 0 &&
          this.automationStoreData.isOptional === false
        ) {
          this.showRedirect()
          return
        }

        if (
          this.automationStoreData.authType &&
          this.automationInputs.connectedAccountId == null
        ) {
          //prefill the input with first account
          if (this.automationStoreData.isOptional === false) {
            this.inputData['connectedAccountId'] = response.data[0]._id
            this.addConnectedAccountId({
              payload: response.data[0]._id
            })
          }
        }
      }
    },

    async uploadCsvFile() {
      this.csvMenu = true
      const formData = new FormData()
      formData.append('filename', this.file)
      this.loading = true
      const response = await uploadCsv(formData)
      this.csvUrl = response.data.csvUrl
      this.addAutomationInputData({
        payload: { csvUrl: response.data.csvUrl, fileName: this.file.name }
      })
      await this.getAccounts()

      this.loading = false
    },

    async selectUpdate(e, input, value) {
      if (input?.name === 'connectedAccountId') {
        this.addConnectedAccountId({
          payload: e
        })

        return
      }

      for (const data of this.automationStoreData.inputList) {
        if (input?.name === data.name) {
          this.csvInputData = { ...this.csvInputData, [input.name]: e }
        }
      }
    },

    validationSuccess() {
      this.pageLoading = true
      this.$emit('resetFromValidation')
      this.triggerValidation = false
      this.emitFormValidate()
    },

    emitFormValidate() {
      //As the trigger is from the navBar
      if (this.validateForm !== false) {
        this.$emit('AfterFormValidateFromNav')
      } else {
        this.$emit('AfterFormValidate')
      }
    },
    checkAndUpdateValidationStatus(data) {
      if (data.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
      this.triggerFormStatus = false
    },
    validationFailed() {
      this.triggerValidation = false
      this.$emit('resetFromValidation')
    },
    showRedirect() {
      let isIntegration =
        this.automationStoreData.platformId === constants.OPENAI_PLATFORM_ID
      this.$refs.toast.addToast({
        timeout: 100000,
        text: 'Account Not Connected!',
        color: 'warning',
        caption:
          'You need to connect your account to run the automation, click the button below to connect your account',
        action: true,
        actionText: 'Click Here',
        actionHref: isIntegration
          ? '/settings-integrations/all-integrations'
          : '/accounts',
        close: true
      })
    },
    setDesktopToggle() {
      const { authType, isSocialAccountOptional, isOptional } =
        this.automationStoreData

      const isCookieWithOptional =
        authType === 'cookie' && (isSocialAccountOptional || isOptional)

      const shouldShowDesktopButton =
        isCookieWithOptional ||
        authType === 'undefined' ||
        authType !== 'cookie'

      if (shouldShowDesktopButton) {
        this.showDesktopButton = true

        if (this.automationDesktopData?.name) {
          this.desktopData = this.automationDesktopData
          this.desktopSelected = true
        }
      }
    },
    onDesktopSelect(data) {
      this.desktopSelected = true
      this.desktopData = data
      this.addAutomationDesktopData(data)
    },
    onDesktopRemove() {
      this.desktopData = null
      this.desktopSelected = false
      this.addAutomationDesktopData(null)
    },
    /**
     * This function displays a verification message to the user in the form of a toast notification.
     */
    showMessage() {
      const message = showVerifyMessage(this.userData)
      const toastOptions = {
        timeout: 100000,
        text: 'Account Not Verified',
        color: 'warning',
        caption: message,
        action: true,
        actionText: 'Click Here To Verify',
        actionHref: '/settings-verify',
        close: true
      }
      this.$refs.toast.addToast(toastOptions)
    }
  }
}
</script>
