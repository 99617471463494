<template>
  <div>
    <div v-if="label" class="mb-1.5">
      <label :class="labelClass" class="flex">
        {{ label }} {{ isRequired === false ? '(Optional)' : null }}
        <SvgIcon
          class="mb-3 ml-1 h-2 w-2 text-red-600"
          name="star"
          v-if="isRequired"
        />
      </label>
    </div>

    <div
      ref="dropZoneRef"
      :class="{
        relative: isOverDropZone && attachmentSpecifications
      }"
    >
      <div
        v-if="isOverDropZone && attachmentSpecifications"
        class="absolute top-0 z-10 h-full w-full p-0.5 transition-all duration-300 ease-in-out"
      >
        <div
          class="flex h-full items-center justify-center rounded bg-blue-50 opacity-90 outline-dashed outline-2"
        >
          <div>
            <SvgIcon name="upload" />
          </div>
          Drop your files Here
        </div>
      </div>
      <div>
        <WorkflowRichTextEditor
          @blur="$emit('blur', $event)"
          @templateInserted="templateInserted"
          @templateRemoved="templateRemoved"
          v-model="componentValue.text"
          placeholder="Write your message or drag and drop files here"
          :previousNodes="previousNodes"
          :personalisedTags="personalisedTags"
          :hasFileAttachment="!!componentValue.file"
          :description="description"
          :isPromptIdProvided="isPromptIdProvided"
          :isPremium="isPremium"
          :platformOperationId="platformOperationId"
        >
          <template #toolbar>
            <!-- if attchment specification is provided show button  -->
            <label
              for="file-upload"
              v-if="attachmentSpecifications"
              class="cursor-pointer rounded p-1 hover:bg-gray-200"
            >
              <SvgIcon class="h-5 w-5" name="attachment" />
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                class="sr-only"
                ref="fileUploadInput"
                v-on:change="onDrop()"
              />
            </label>
          </template>
        </WorkflowRichTextEditor>
      </div>
    </div>

    <!-- File Preview -->
    <div
      v-if="previewUrl"
      class="flex h-fit mt-1 w-full items-center overflow-hidden rounded-lg border border-gray-300 px-4 py-2"
    >
      <figure class="flex flex-1">
        <div class="flex h-[40px] w-[40px] items-center justify-center">
          <img
            :src="previewUrl"
            alt=""
            srcset=""
            class="overflow-clip"
            style="overflow-clip-margin: content-box"
          />
        </div>
        <figcaption v-if="uploadingStatus" class="flex-1 overflow-hidden px-2">
          <div class="flex h-full items-center justify-center text-blue-600">
            <SvgIcon name="spinnerIcon" />
          </div>
        </figcaption>
        <figcaption v-else class="flex-1 overflow-hidden px-2">
          <div class="flex">
            <h3 class="truncate text-sm">
              {{ fileName }}
            </h3>
            <span
              class="text-sm text-gray-500 before:px-1 before:content-['•']"
            >
              {{ getFileSizeInMb(fileSize) }} MB
            </span>
          </div>

          <p class="text-xs text-gray-500">Attached</p>
        </figcaption>
      </figure>
      <button @click="deletePreview()">
        <SvgIcon name="close" />
      </button>
    </div>
  </div>
</template>
<script setup>
import { deleteInputFile, uploadInputFile } from '@/apis/upload'
import { useDropZone } from '@vueuse/core'
import { ref, watch, computed, onMounted } from 'vue'
import SvgIcon from './SvgIcon.vue'
import DescriptionIcon from './DescriptionIcon.vue'
import { useRoute } from 'vue-router'
import WorkflowRichTextEditor from './workflowBuilder/customInput/WorkflowRichTextEditor.vue'

const dropZoneRef = ref(null)
const componentValue = ref({ text: '', file: null })
const file = ref(null)
const previewUrl = ref(null)
const uploadingStatus = ref(true)
const fileId = ref(null)
const fileName = ref(null)
const fileSize = ref(null)
const fileUploadInput = ref(null)
const route = useRoute()
const autoConnectOperationId = '640f16290936e46db5716df5'

onMounted(() => {
  checkForLiAutoConnect()
})

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: { type: Boolean, default: undefined },
  modalClass: String,
  isLabelEditable: Boolean,
  attachmentSpecifications: Object,
  personalisedTags: Array,
  description: String,
  isPremium: {},
  platformOperationId: {}
})

const fileTypeToPreviewURL = {
  pdf: 'https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg',
  csv: 'https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg',
  m4a: 'https://static-00.iconduck.com/assets.00/file-audio-icon-462x512-05b2lgxc.png',
  'x-m4a':
    'https://static-00.iconduck.com/assets.00/file-audio-icon-462x512-05b2lgxc.png'
}

const isPromptIdProvided = computed(() => {
  return componentValue.value.promptId ? true : false
})

// watch for model value change and update the component value
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue?.promptId) {
      componentValue.value = {
        text: `{{${props.modelValue.promptId}.${props.modelValue.promptLabel}}}`,
        file: props.modelValue.file,
        promptId: props.modelValue.promptId,
        promptLabel: props.modelValue.promptLabel
      }
    } else {
      componentValue.value = props.modelValue || { text: '', file: null }
    }
    // if file is present
    if (componentValue.value.file) {
      setPreviewURL(componentValue.value.file)
      fileId.value = componentValue.value.file.fileId
      fileName.value = componentValue.value.file.originalName
      fileSize.value = componentValue.value.file.fileSize
      uploadingStatus.value = false
    }
  },
  { immediate: true }
)

watch(
  () => props.isPremium,
  () => {
    checkForLiAutoConnect()
    emit('update:modelValue', componentValue.value)
  }
)

const checkForLiAutoConnect = () => {
  if (
    route.query.operationId === autoConnectOperationId ||
    props.platformOperationId === autoConnectOperationId
  ) {
    componentValue.value['checkForCharCount'] = true
    componentValue.value['isPremium'] = props.isPremium
  }
}

function setPreviewURL(file) {
  const type = file.originalName?.split('.').pop()

  if (type in fileTypeToPreviewURL) {
    previewUrl.value = fileTypeToPreviewURL[type]
  } else {
    //create image file preview
    previewUrl.value = file.fileUrl
  }
}

function onDrop(files) {
  try {
    // called when files are dropped on zone
    let unsupportedFile = true
    if (files) {
      file.value = files[0]
    } else {
      file.value = fileUploadInput.value.files[0]
    }

    //check if file extenstion is matches the attachment specification
    for (let type of props.attachmentSpecifications.supportedFormats) {
      if (file.value.type.split('/').pop() === type) {
        unsupportedFile = false
        break
      }
    }

    // if file is not supported
    if (unsupportedFile) {
      componentValue.value.file = 'unsupportedFile'
      emit('update:modelValue', componentValue.value)
      return
    }

    //file size check
    if (file.value.size > props.attachmentSpecifications.maxFileSize) {
      componentValue.value.file = 'size limit exceed'
      emit('update:modelValue', componentValue.value)
      return
    }

    if (file.value.type.split('/')[0] === 'image') {
      //create image file preview
      const reader = new FileReader()
      reader.onload = e => {
        previewUrl.value = e.target.result
      }
      reader.readAsDataURL(file.value)
    } else if (file.value.type.split('/').pop() in fileTypeToPreviewURL) {
      previewUrl.value = fileTypeToPreviewURL[file.value.type.split('/').pop()]
    } else {
      previewUrl.value =
        'https://static-00.iconduck.com/assets.00/share-emoji-512x512-j2qmf7um.png'
    }

    //upload the file to s3
    uploadFile()

    // Reset the file input value so it can trigger again for the same file
    if (fileUploadInput.value) {
      fileUploadInput.value.value = ''
    }
  } catch (error) {
    console.log(error)
    // Also reset on error
    if (fileUploadInput.value) {
      fileUploadInput.value.value = ''
    }
  }
}

async function uploadFile() {
  try {
    const formData = new FormData()
    formData.append('filename', file.value)
    uploadingStatus.value = true
    const response = await uploadInputFile(formData)
    if (response['success']) {
      fileId.value = response.data.fileId

      const fileUrl = response.data.fileUrl

      const txFileDetails = {
        fileId: fileId.value,
        originalName: file.value.name,
        fileSize: file.value.size,
        fileName: fileUrl.split('/').pop(),
        fileUrl,
        key: fileUrl.split('.com/')[1],
        bucket: fileUrl.split('/')[2].split('.')[0],
        region: fileUrl.split('/')[2].split('.')[2]
      }
      componentValue.value.file = txFileDetails
      emit('update:modelValue', componentValue.value)
      emit('blur')
    } else {
      throw error
    }
  } catch (error) {
    componentValue.value.file = 'api error'
    emit('update:modelValue', componentValue.value)
  } finally {
    uploadingStatus.value = false
  }
}

//check if file is over the dropzone
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

//convert bytes to MB
function getFileSizeInMb(fileSize) {
  return (fileSize / (1024 * 1024)).toFixed(2)
}

watch(file, () => {
  if (file.value) {
    fileSize.value = getFileSizeInMb(file.value.size)
    fileName.value = file.value.name
  }
})

//emits text value everytime it gets updated
watch(
  () => componentValue.value.text,

  () => {
    emit('update:modelValue', componentValue.value)
  }
)

function templateInserted() {
  const inputString = componentValue.value.text
  const regex = /{{(.*?)}}/g
  const match = regex.exec(inputString)
  if (match) {
    const promptId = match[1].split('.')[0]
    componentValue.value['promptId'] = promptId
    componentValue.value.text = ''
    componentValue.value.promptLabel = match[1].split('.')[1]
  }
  emit('update:modelValue', componentValue.value)
  emit('blur')
  emit('toggleOpenAiAccounts', true)
}

function templateRemoved() {
  componentValue.value.text = ''
  delete componentValue.value.promptId
  delete componentValue.value.promptLabel
  emit('update:modelValue', componentValue.value)
  emit('blur')
  emit('toggleOpenAiAccounts', false)
}

async function deletePreview() {
  try {
    uploadingStatus.value = true
    const response = await deleteInputFile(fileId.value)
    if (response['success']) {
      file.value = null
      previewUrl.value = null
      componentValue.value.file = null
      uploadingStatus.value = false
      emit('update:modelValue', componentValue.value)
      emit('blur')
    }
  } catch (error) {
    console.log(error)
  }
}
const emit = defineEmits([
  'update:modelValue',
  'label-update',
  'blur',
  'toggleOpenAiAccounts',
  'error'
])
</script>

<style>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}

#custom-button {
  margin-right: 10px;
}
</style>
