<template>
  <OutputTagsModal
    @close="toggleModalClose"
    :spanList="spanList"
    v-if="modalActive"
  />
  <desktopSelectModal
    v-if="openDesktopModal"
    :currentSelected="desktopData"
    @close="openDesktopModal = false"
    @desktopSelected="onDesktopSelect"
    @desktopRemove="onDesktopRemove"
  />
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div v-if="loading" class="flex h-full w-full items-center justify-center">
      <Spinner size="large" />
    </div>
    <div
      v-else
      class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6"
    >
      <div class="flex-1">
        <ValidationForm
          class="grid grid-cols-2 gap-6"
          :platformInputs="platformInputs"
          :previousNodes="[]"
          :inputs="inputData"
          :triggerValidation="triggerValidation"
          :triggerFormStatus="triggerFormStatus"
          @validationSuccess="submitCreate"
          @validationFailed="validationFailed"
          @input-update="onInputUpdate"
          @input-blur="onInputBlur"
          @form-status="checkAndUpdateValidationStatus"
          @toggleOpenAiAccounts="toggleOpenAiAccounts"
        />
        <TwitterThread
          v-if="$route.query.operationId === twitterThreadId"
          :twitterProfileData="twitterProfileData"
          @threadUpdate="threadUpdate"
          :inputData="inputData"
        />

        <!-- <div class="mt-5 grid grid-cols-7 items-start">
          <InputOutputCard
            v-if="automationStoreData.inputList"
            type="input"
            :lists="[
              automationStoreData.inputList,
              automationStoreData.outputList
            ]"
          />

          <InputOutputCardConnector
            v-if="automationStoreData.inputList"
            :outputListLength="automationStoreData.outputList"
          />

          <InputOutputCard
            v-if="automationStoreData.outputList"
            type="output"
            :lists="[
              automationStoreData.inputList,
              automationStoreData.outputList
            ]"
            @toggleModalOpen="toggleModalOpen"
          />
        </div> -->
      </div>

      <div class="mt-auto flex items-center justify-end gap-x-2">
        <Button
          @click="openDesktopModal = true"
          v-if="showDesktopButton === true"
          :text="
            desktopSelected === false ? 'Select Desktop' : desktopData.name
          "
          :color="desktopSelected === false ? 'tertiary' : 'success'"
          rightIcon="desktop"
        />

        <div v-if="!userData.isEmailVerified || !userData.isPhoneVerified">
          <Button
            color="gray"
            @click="showMessage"
            text="Run"
            rightIcon="right-arrow"
          />
        </div>
        <div v-else>
          <Button
            v-if="isScheduled"
            :showLoader="buttonLoading"
            @click="stopSchedule"
            text="Stop Schedule"
            color="danger"
            rightIcon="right-arrow"
          />
          <Button
            v-else
            :showLoader="buttonLoading"
            :color="validationStatus ? 'successFilled' : 'gray'"
            :disabled="!validationStatus"
            @click="triggerValidation = true"
            :text="automationScheduleData ? 'Schedule' : 'Run'"
            rightIcon="right-arrow"
          />
        </div>
      </div>
    </div>
  </div>
  <ToastContainer class="w-1/5" ref="toast" />
</template>

<script>
import Input from '@/components/Input.vue'

import OutputTagsModal from '@/components/automationStore/OutputTagsModal.vue'
import Button from '@/components/Button.vue'
import InputOutputCard from '@/components/InputOutputCard.vue'
import InputOutputCardConnector from '@/components/InputOutputCardConnector.vue'
import Select from '@/components/Select.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { mapActions, mapState } from 'vuex'
import { constants } from '@/common/constants'
import TwitterThread from '@/views/thread/TwitterThread.vue'
//API
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import { getWorkflowNode, pauseSchedule } from '@/apis/workflows'
import Spinner from '@/components/Spinner.vue'
import desktopSelectModal from '@/components/automationStore/desktopSelectModal.vue'
import { showVerifyMessage } from '@/common/functions/automationStore'
export default {
  name: 'DirectInputMain',
  components: {
    Input,
    Button,
    InputOutputCard,
    InputOutputCardConnector,
    Select,
    OutputTagsModal,
    ValidationForm,
    Spinner,
    ToastContainer,
    TwitterThread,
    desktopSelectModal
  },
  emits: [
    'resetFromValidation',
    'AfterFormValidateFromNav',
    'AfterFormValidate',
    'success',
    'error'
  ],
  data() {
    return {
      inputData: {},
      triggerValidation: false,
      triggerFormStatus: false,
      platformInputs: null,
      modalActive: false,
      spanList: null,
      loading: true,
      buttonLoading: false,
      connectedAccounts: null,
      showOpenAiAccountsFlag: false,
      twitterProfileData: null,
      threadText: null,
      twitterThreadId: constants['TWITTER_THREAD_ID'],
      showDesktopButton: false,
      openDesktopModal: false,
      desktopSelected: false,
      desktopData: null
    }
  },
  props: {
    validateForm: {
      required: true
    },
    isScheduled: {
      required: true
    }
  },
  async created() {
    this.platformInputs = null

    await this.createPlatformInputs()
    this.loading = false
  },
  mounted() {
    this.buttonLoading = false
    this.triggerFormStatus = true
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['automationDesktopData']),
    ...mapState('user', ['desktopUnlimited', 'userData']),
    value() {
      return name => {
        return (name = this.automationInputs[name]
          ? this.automationInputs[name]
          : '')
      }
    }
  },
  watch: {
    validateForm(newVal, oldVal) {
      if (newVal === true) {
        this.triggerValidation = true
      }
    }
  },

  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['setValidationStatus']),
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addAutomationInputData']),
    ...mapActions('automationStore', ['addAutomationDesktopData']),

    async submitCreate(inputs) {
      if (this.$route.query.operationId === this.twitterThreadId) {
        if (this.threadText === null || this.threadText[0].text === '') {
          this.validationFailed()
          this.$emit('error', 'Provide at least 1 tweet to run the automation')
          return
        }
      }
      this.setValidationStatus({
        payload: true
      })
      this.buttonLoading = true

      this.$emit('resetFromValidation')
      for (const input in inputs) {
        if (input === 'connectedAccountId') {
          this.addConnectedAccountId({
            payload: inputs[input] === '' ? null : inputs[input]
          })
        }
        if (input === 'threadText') continue
        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }
      // As the trigger is from the navBar
      if (this.validateForm !== false) {
        this.$emit('AfterFormValidateFromNav')
      } else {
        this.$emit('AfterFormValidate')
      }
    },
    async createPlatformInputs() {
      this.setDesktopToggle()
      this.platformInputs = this.automationStoreData.inputList.slice()

      //no inputs in automation so set validation true by default
      if (this.platformInputs.length === 0) {
        this.setValidationStatus({
          payload: true
        })
      }

      if (this.automationStoreData.socialPlatformId) {
        let socialPlatformResponse = await getConnectedAccounts(
          this.automationStoreData.socialPlatformId
        )

        this.platformInputs.unshift({
          name: 'texAuSocialAccountId',
          type: 'select',
          label: 'Social Account',
          isRequired: true,
          choices: socialPlatformResponse.data.map(account => ({
            value: account._id,
            label: account.name,
            img:
              account.platform.picture === ''
                ? 'error'
                : account.platform.picture
          })),
          includeSearch: true,
          description: 'Your Social Account'
        })
      }

      this.platformInputs = this.platformInputs.filter(
        data => data.name !== 'replyDetection' && data.name !== 'threadText'
      )
      let response
      if (this.automationStoreData.authType) {
        if (this.automationStoreData.isSocialAccountOptional) {
          await this.setInputs(response)
          return
        }
        response = await getConnectedAccounts(
          this.automationStoreData.platformId
        )
        this.connectedAccounts = response.data

        if (
          response.data.length === 0 &&
          this.automationStoreData.isOptional === false
        ) {
          this.platformInputs.unshift({
            name: 'connectedAccountId',
            type: 'select',
            label: 'Account',
            isRequired: this.automationStoreData.isOptional ? false : true,
            choices: [],
            description: `Your ${this.automationStoreData.platform} Account`
          })
          this.showRedirect()
          return
        }
      }
      await this.setInputs(response)
    },
    async setInputs(response) {
      //prefill the connected account with first account
      let connectedAccountId = response?.data[0]?._id
      this.twitterProfileData = response?.data[0]
      //check if inputs are stored in vuex
      if (Object.keys(this.automationInputs).length > 0) {
        // prefill all the inputs stored in vuex
        this.inputData = this.automationInputs
        //this is to show openAi account for AI message automations
        if (
          this.automationInputs?.message?.promptId ||
          this.automationInputs?.messageContent?.promptId
        ) {
          await this.toggleOpenAiAccounts(true)
        }
        connectedAccountId = this.automationInputs.connectedAccountId
        this.twitterProfileData = response?.data.find(
          item => item._id === this.automationInputs.connectedAccountId
        )
      } else if (
        //check if vuex is empty as well as node id is provided in url
        Object.keys(this.automationInputs).length === 0 &&
        !!this.$route.query.nodeId
      ) {
        const nodeResponse = await getWorkflowNode(
          this.$route.query.workflowId,
          this.$route.query.nodeId
        )
        //set the inputs with the api inputs
        this.inputData = {
          ...this.inputData,
          ...nodeResponse.data.inputs
        }
        //this is to show openAi account for AI message automations
        if (
          nodeResponse.data?.inputs?.message?.promptId ||
          nodeResponse.data?.inputs?.messageContent?.promptId
        ) {
          await this.toggleOpenAiAccounts(true)
        }

        connectedAccountId = nodeResponse.data.connectedAccountId
        this.twitterProfileData = response?.data.find(
          item => item._id === nodeResponse.data.connectedAccountId
        )
      }
      // check if authtype is provided
      if (this.automationStoreData.authType) {
        if (this.automationStoreData.isSocialAccountOptional) {
          return
        }
        if (this.automationStoreData.isOptional === false) {
          this.inputData['connectedAccountId'] = connectedAccountId
        }

        const connectedAccount = this.connectedAccounts.find(
          ({ _id }) => _id === this.inputData.connectedAccountId
        )
        this.platformInputs.unshift({
          name: 'connectedAccountId',
          type: 'select',
          label: 'Account',
          isRequired: this.automationStoreData.isOptional ? false : true,
          choices: response.data.map(account => ({
            value: account._id,
            label: account.name,
            img:
              account.platform.picture === ''
                ? 'error'
                : account.platform.picture
          })),

          leftImageUrl: connectedAccount?.platform?.picture,
          isPremium: connectedAccount?.isPremium,
          includeSearch: true,
          description:
            this.automationStoreData.platform === 'Google'
              ? `Your Rocket Scrape/Scraper API Account`
              : `Your ${this.automationStoreData.platform} Account`
        })
      }
    },
    setDesktopToggle() {
      const { authType, isSocialAccountOptional, isOptional } =
        this.automationStoreData

      const isCookieWithOptional =
        authType === 'cookie' && (isSocialAccountOptional || isOptional)

      const shouldShowDesktopButton =
        isCookieWithOptional ||
        authType === 'undefined' ||
        authType !== 'cookie'

      if (shouldShowDesktopButton) {
        this.showDesktopButton = true

        if (this.automationDesktopData?.name) {
          this.desktopData = this.automationDesktopData
          this.desktopSelected = true
        }
      }
    },

    validationFailed() {
      this.setValidationStatus({
        payload: false
      })
      this.triggerValidation = false
      this.$emit('resetFromValidation')
      if (this.validateForm !== false) {
        this.$emit('AfterFormValidateFromNav')
      }
    },

    checkAndUpdateValidationStatus(data) {
      if (data.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
      this.triggerFormStatus = false
    },

    toggleModalClose() {
      this.modalActive = false
    },
    toggleModalOpen(data) {
      this.spanList = data
      this.modalActive = true
    },
    showRedirect() {
      let isIntegration =
        this.automationStoreData.platformId === constants.OPENAI_PLATFORM_ID
      this.$refs.toast.addToast({
        timeout: 100000,
        text: 'Account Not Connected!',
        color: 'warning',
        caption:
          'You need to connect your account to run the automation, click the button below to connect your account',
        action: true,
        actionText: 'Click Here',
        actionHref: isIntegration
          ? '/settings-integrations/all-integrations'
          : '/accounts',
        close: true
      })
    },
    async onInputUpdate(inputs, validationResult) {
      //add inputs in vuex
      for (const input in inputs) {
        if (input === 'connectedAccountId') {
          this.addConnectedAccountId({
            payload: inputs[input] === '' ? null : inputs[input]
          })
        }
        if (input === 'threadText') continue
        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }
      //update vuex validation status
      if (validationResult.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
      const inputObj = { ...inputs }

      // update connected account picture on every change
      const account = this.connectedAccounts?.find(
        acc => acc._id === inputObj.connectedAccountId
      )

      this.twitterProfileData = account
      this.platformInputs = this.platformInputs.map(input => {
        if (input.name === 'connectedAccountId') {
          return {
            ...input,
            leftImageUrl: account?.platform?.picture,
            isPremium: account?.isPremium
          }
        }
        if (input.name === 'messageContent') {
          return {
            ...input,
            isPremium: account?.isPremium
          }
        }
        return input
      })
    },

    async onInputBlur(values, validationResult, input) {
      if (validationResult.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
    },
    async stopSchedule() {
      try {
        const response = await pauseSchedule(this.$route.query.workflowId)
        if (response['success']) {
          this.$emit('success', 'Automation Schedule Stopped Successfully')
          this.$emit('setScheduledStatus', false)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async toggleOpenAiAccounts(data) {
      try {
        if (data) {
          if (this.showOpenAiAccountsFlag) {
            return
          }
          const response = await getConnectedAccounts(
            constants.OPENAI_PLATFORM_ID
          )
          if (response['success']) {
            this.platformInputs.push({
              name: 'txOpenAiAccountId',
              type: 'select',
              label: 'OpenAI Account',
              isRequired: true,
              choices: response.data.map(account => ({
                value: account._id,
                label: account.name,
                img:
                  account.platform.picture === ''
                    ? 'error'
                    : account.platform.picture || account.platform.logoUrl
              }))
            })
            this.showOpenAiAccountsFlag = true
          } else {
            throw response.message
          }
        } else {
          this.showOpenAiAccountsFlag = false
          this.platformInputs.pop()
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    threadUpdate(data) {
      this.threadText = data
      this.addAutomationInputData({
        payload: { threadText: this.threadText }
      })
    },
    onDesktopSelect(data) {
      this.desktopSelected = true
      this.desktopData = data
      this.addAutomationDesktopData(data)
    },
    onDesktopRemove() {
      this.desktopData = null
      this.desktopSelected = false
      this.addAutomationDesktopData(null)
    },
    /**
     * This function displays a verification message to the user in the form of a toast notification.
     */
    showMessage() {
      const message = showVerifyMessage(this.userData)
      const toastOptions = {
        timeout: 100000,
        text: 'Account Not Verified',
        color: 'warning',
        caption: message,
        action: true,
        actionText: 'Click Here To Verify',
        actionHref: '/settings-verify',
        close: true
      }
      this.$refs.toast.addToast(toastOptions)
    }
  }
}
</script>

<style></style>
